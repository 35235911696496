<template>
  <div>
      <b-row class="mb-3" >
            <b-col class="text-right">
                <b-button size="sm" @click="createModal" class="mr-1" variant="primary"><i class="fas fa-plus-circle" v-if="$auth.check('distributors.create')"></i> Lieferant zuweisen</b-button>
            </b-col>
        </b-row>

        <b-row v-if="distributors.length >= 1">
            <b-col>
                <vue-good-table 
                    :columns="columns"
                    :rows="distributors"
                    :search-options="{ 
                        enabled: true
                    }"
                    :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        perPage: 20,
                        perPageDropdownEnabled: true,
                        nextLabel: 'Nächste',
                        prevLabel: 'Vorherige',
                        rowsPerPageLabel: 'Zeilen pro Seite',
                        ofLabel: 'von',
                        pageLabel: 'Seite',
                        allLabel: 'Alle',
                    }"
                    styleClass="vgt-table condensed striped">

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'action'">
                            <router-link class="mr-1 btn btn-warning btn-sm" :to="{name: 'distributors.details', params: {id: props.row.id}}" v-if="$auth.check('distributors.show')"><i class="fas fa-edit"></i></router-link>
                            <b-button size="sm" @click="deleteDistributor(props.row.id)" variant="danger" v-if="$auth.check('distributors.destroy')"><i class="fas fa-trash" ></i></b-button>
                        </span>    
                    </template>
                    
                </vue-good-table>
            </b-col>
        </b-row>
    
        <b-row v-if="distributors.length == 0">
            <b-col>
                <h4>Dieser Hersteller hat keine Lieferanten</h4>
            </b-col>
        </b-row>

        <b-modal id="distributorModal" title="Lieferanten zuweisen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="assignDistributors">
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-striped">
                        <thead>
                                <th><input type="checkbox" v-model="selectAll" /></th>
                                <th>Lieferantenummer</th>
                                <th>Name</th>
                        </thead>
                        <tbody>
                            <tr v-for="(distributor) in allDistributors.data" :key="distributor.id">
                                <td><input type="checkbox" v-model="selectedDistributors" :value="distributor.id" number/></td>
                                <td>{{ distributor.distributornumber }}</td>
                                <td>{{ distributor.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="clearfix align-middle card-footer">
                        <pagination class="float-left" :data="allDistributors" @pagination-change-page="getDistributors" :limit="3"></pagination>
                        
                        <select class="float-right form-control-sm" v-model="params.per_page">
                            <option value="25">25 Lieferanten</option>
                            <option value="50">50 Lieferanten</option>
                            <option value="75">75 Lieferanten</option>
                            <option value="100">100 Lieferanten</option>
                            <option value="125">125 Lieferanten</option>
                            <option value="150">150 Lieferanten</option>
                        </select>
                        <p v-if="notEmptyObject(allDistributors)" class="float-right mr-2">Anzeige Eintrag {{ allDistributors.meta.from }} - {{ allDistributors.meta.to }} von {{ allDistributors.meta.total }}</p>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "Distributors",

    props: [
        'distributors',
    ],

    data(){
        return {
            columns: [
                {field: "name", label: "Name"},
                {field: "street", label: "Straße"},
                {field: "house_number", label: "Nr."},
                {field: "zipcode", label: "PLZ"},
                {field: "city", label: "Stadt"},
                {field: "action", label: "Actions", tdClass: 'text-center', width: '7%'},
            ],
            form: new window.Form({
                id: "",
                producer_id: "",
                name: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
            }),
            editMode: false,
            modalTitle: '',
            selectedDistributors: [],
            params: {
                        per_page: 25,
                    },
            allDistributors: [],
            }
        },

    computed: {
        selectAll: {
            get: function () {
                return this.allDistributors.data ? this.selectedDistributors.length == this.allDistributors.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.allDistributors.data.forEach(function (distributor) {
                        selected.push(distributor.id);
                    });
                }

                this.selectedDistributors = selected;
            }
        }
    },

    watch: {
        params: {
            handler () {
                this.getDistributors();
            },
            deep: true
        },
    },

    methods:{
        assignDistributors(){
            this.$Progress.start();
            this.axios
                .post("/producers/" + this.$route.params.id + '/distributors', 
                    {'selectedDistributors': this.selectedDistributors}
                )
                .then(() => {
                    //this.articles = response.data;
                    this.$parent.loadProducer();
                    this.$swal({
                        icon: "success",
                        title: "Lieferanten wurden hinzugefügt!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.selectedDistributors = [];
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                //Edit User
                this.editDistributor(id);
            }
            else
            {
                //Create User
                this.createDistributor();
            }
        },

        createModal() {
            this.editMode = false;
            this.modalTitle = "Neuen Lieferanten anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("distributorModal");
        },

        editModal(Distributor){
            this.editMode = true;
            this.modalTitle = "Lieferant editieren";
            this.form.clear();
            this.form.reset();
            this.form.fill(Distributor);
            this.$bvModal.show("distributorModal");
        },
        

        deleteDistributor(distributor){
            this.$swal({
                title: "Soll die Verknüpfung zwischen diesem Lieferanten und dem Hersteller wirklich gelöscht werden?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    var distributors = [distributor];
                    this.axios
                        .delete("/producers/" + this.$parent.producer.id + '/distributors', {
                            data: {
                                'selectedDistributors': distributors
                            }
                        })
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Distributor wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.$parent.loadProducer();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        getDistributors(page=1)
        {
            this.$Progress.start();
            this.axios
                .get("/distributors", {
                    params: {
                        page,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.allDistributors = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    mounted() {
    this.getDistributors();

  }
}
</script>

<style>

</style>